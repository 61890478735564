import './Bingo.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';
import { isBingo3to1 } from '../../Util/Util';
import Lottie from 'react-lottie';
import * as ani3x3 from '../../LottieRes/3x3.json';
import * as aniStarter from '../../LottieRes/starter.json';
import * as aniBasic from '../../LottieRes/basic.json';
import * as aniDelux from '../../LottieRes/delux.json';
import * as aniPremium from '../../LottieRes/premium.json';

const getCardImg = (isOn, gameCode) =>
{
  switch(gameCode)
  {
    case 2: //프리미움
      return isOn ? "bingo/premiumon.png" : "bingo/premiumoff.png";
    case 3: //디럭스
      return isOn ? "bingo/delux.png" : "bingo/deluxoff.png";
    case 4: //베이직
      return isOn ? "bingo/basicon.png" : "bingo/basicoff.png";
    case 5: //스타터
      return isOn ? "bingo/starteron.png" : "bingo/starteroff.png";
    default:
      return "";
  }
}

const getCardAni = (gameCode) =>
{
  switch(gameCode)
  {
    case 1: //집중형프리미움
      return ani3x3;
    case 2: //일반형프리미움
      return aniPremium;
    case 3: //일반형디럭스
      return aniDelux;
    case 4: //일반형베이직
      return aniBasic;
    case 5: //일반형스타터
      return aniStarter;
    default:
      return null;
  }
}

const BingoCardAni = (props) =>
{
  if (isBingo3to1(props.gameCode))
  {
    if (!props.selected)
    {
      return(
        <div className="bpcdanibg" style={{backgroundImage: `url(${GetImgUrl(getCardImg(false, props.gameCode))})`}}>
        </div>
      );
    }
    else
    {
      const aniOption = {
        loop: true,
        autoPlay: true,
        animationData: getCardAni(props.gameCode),
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

      return(
        <div className="bpcdanibg colec" style={{backgroundImage: `url(${GetImgUrl(getCardImg(true, props.gameCode))})`}}>
          <Lottie options={aniOption}
            width={237}
            height={78}
            isStopped={false}
            isPaused={false}
          />
        </div>
      );
    }
  }
  else
  {
    const aniOption = {
      loop: true,
      autoPlay: true,
      animationData: getCardAni(props.gameCode),
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return(
      <div className="bpcdanibg rowcc">
        <Lottie options={aniOption}
          width={250}
          height={250}
          isStopped={false}
          isPaused={false}
        />
      </div>
    );
  }
  
}

export default BingoCardAni;