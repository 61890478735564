import React from 'react';
import './Bingo.css';
import '../../App.css';
import { APP_MODE, getBingoImageUrl, GetImgUrl, LOG_ENABLE } from '../../URL/AppUrl';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { checkVideoBingo, isBingo3to1 } from '../../Util/Util';
import ToastMessage from '../Common/ToastMessage';


//빙고팡팡 미리보기
class BingoPlay extends React.Component
{
  state = {
    loading: false,
    
    gameUrl: '',
    showToastMsg: false,
    toastMessage: '토스트 메시지',
  };

  gameData = null;

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setGameUrl = (v) =>
  {
    this.setState((prevState) => ({...prevState, gameUrl : v}));
  }

  showToastMessage = (msg) =>
  {
    this.setState((prevState) => ({...prevState, showToastMsg : true, toastMessage: msg}));
    setTimeout(this.hideToastMessage, 2000);
  }

  hideToastMessage = () =>
  {
    this.setState((prevState) => ({...prevState, showToastMsg : false}));
  }

  componentDidMount() {
    window.addEventListener("message", this.handleChildMessage);

    this.setGameUrl(this.getGameResUrl());
  }

  componentWillUnmount = () =>
  {
    window.removeEventListener("message", this.handleChildMessage);
  }

  //게임 리소스 주소 얻기
  getGameResUrl = () =>
  {
    return this.props.item.gameUrl;
  }

  getTestSpinUrl = () =>
  {
    if (APP_MODE === 0)
    {
      return "https://test2.bravo6.kr:5001/adg/testspin/";
    }
    else if (APP_MODE === 1)
    {
      return "https://test.snsfly.kr:5001/adg/testspin/";
    }
    else
    {
      return "https://api.snsfly.kr/adg/testspin/";
    }
  }

  getSpinUrl = () =>
  {
    if (APP_MODE === 0)
      {
        return "https://test2.bravo6.kr:5001/adg/spin/";
      }
      else if (APP_MODE === 1)
      {
        return "https://test.snsfly.kr:5001/adg/spin/";
      }
      else
      {
        return "https://api.snsfly.kr/adg/spin/";
      }
  }

  generateSymbolList = () =>
  {
    if (LOG_ENABLE)
      console.log(`symbols: ${JSON.stringify(this.props.item.symbolInfo)}`);

    if (isBingo3to1(this.props.item.gt))
    {
      let sl = [];

      for (let i = 0;i < this.props.item.symbolInfo.length; ++i)
      {
        switch(this.props.item.symbolInfo[i].si)
        {
          case 0:
            sl.push({si: -1, fn: getBingoImageUrl(this.props.item.symbolInfo[i].fn)});
            break;
          case 1:
            sl.push({si: -2, fn: getBingoImageUrl(this.props.item.symbolInfo[i].fn)});
            break;
          case 2:
            sl.push({si: -3, fn: getBingoImageUrl(this.props.item.symbolInfo[i].fn)});
            break;
          case 3:
            sl.push({si: 1, fn: getBingoImageUrl(this.props.item.symbolInfo[i].fn)});
            break;
          case 4:
            sl.push({si: 2, fn: getBingoImageUrl(this.props.item.symbolInfo[i].fn)});
            break;
          case 5:
            sl.push({si: 3, fn: getBingoImageUrl(this.props.item.symbolInfo[i].fn)});
            break;
        }
      }
      
      return sl;
    }
    else
    {
      const sl = [];

      for (let i = 0;i < this.props.item.symbolInfo.length; ++i)
      {
        sl.push({si: i, fn: getBingoImageUrl(this.props.item.symbolInfo[i].fn)});
      }

      return sl;
    }
    
  }
  //빙고 게임에 게임 데이터를 넘김
  sendGameData = () =>
  {
    const data = {
      id: "gameInfo",

      gi: {
        gc: this.props.item.gt,
        sl: this.generateSymbolList(),
        frv: 1, //리뷰모드인가?
        title: this.props.item.gti,
        lkl: this.props.item.llk,
        lkr: this.props.item.rlk,
        lf: this.props.item.lbt,
        rf: this.props.item.rbt,
        lw: 0,
        rw: 0,
        bimg: getBingoImageUrl(this.props.item.imgInfo.gimg),
        blk: '',
        bc: 2, //베팅금액, 필요한가?
        aidx: this.props.item.idx, //광고 고유번호
        tgc: 5,
        cnt: 5, //남은 스핀횟수
        spc: 798,
        vc: checkVideoBingo(this.props.item.gt) ? 2: 0, //남은 비디오시청횟수
        jc: this.props.item.gfc, //남은 선물개수
        vurl: getBingoImageUrl(this.props.item.imgInfo.vdf),
        rbs: getBingoImageUrl(""), //리소스 다운로드 주소
        su: this.getSpinUrl(),
        tu: this.getTestSpinUrl(),
        mgfc: this.props.item.gfc,
        gfc: this.props.item.gfc - this.props.item.st1,
      },

      ui: {
        ui: LoginStoreInstance.userIdx,
        tk: LoginStoreInstance.token,
        tm: 1000, //이용자보유머니
      }
    };

    this.sendMsgToChild(JSON.stringify(data));
  }

  //iframe에 데이터를 넘김, msg : string
  sendMsgToChild = (msg) =>
  {
    if (LOG_ENABLE)
      console.log(`send msg to child`);

    document.getElementById('idchildview').contentWindow.postMessage(msg, '*');
  }

  handleChildMessage = (e) =>
  {
    if (LOG_ENABLE)
      console.log(`handleChildMessage : ${e.data}`);

    try
    {
      const json = JSON.parse(e.data);
  
      switch(json.id)
      {
        case 0: //창닫기 요청
          this.props.handleClose();
          return;
        case 1:
        case 5:
          this.showToastMessage(json.msg);
          return;
        case 4: //게임정보 요청
          this.sendGameData();
          break;
        case 6: //왼버튼 클릭
          window.open(this.props.item.llk, "_blank");
          return;
        case 7: //오른버튼 클릭
          window.open(this.props.item.rlk, "_blank");
          return;
        case 8: //영상시청시작
          this.sendMsgToChild(JSON.stringify({id: "videost", ret: 0}));
          return;
        case 9: //영상 시청 종료
          this.sendMsgToChild(JSON.stringify({id: "videoed", ret: 0}));
          return;
        default:
          break;
      }
    }
    catch(e)
    {
      if (LOG_ENABLE)
        console.log(`handleChildMessage failed :${e.toString()}`);
    }
  }

  render()
  {
    return (
      <div className='bingoplaybg colcc'>
        {
          this.state.showToastMsg ? (<ToastMessage msg={this.state.toastMessage}/>) : null
        }
        <div className='bingoplay colcc'>
          <div className='wd100p rowrc'>
            <img src={GetImgUrl("com/close.png")} alt="닫기" className='btncur mgrt15 mgbt20' onClick={this.props.handleClose}/>
          </div>
          {
            this.state.gameUrl ? (
              <iframe className='bingoplayview' src={this.state.gameUrl} id="idchildview">
              </iframe>
            ) : null
          }
        </div>
      </div>
    );
  }
}

export default BingoPlay;