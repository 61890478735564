import React, { Fragment } from 'react';
import './Bingo.css';
import '../../App.css';
import { APP_MODE, GetImgUrl  } from '../../URL/AppUrl';
import {  checkVideoBingo, getBingoDefaultPrice, getBingoTotalGameCount, getBingoTotalVideoCount, GetDateToSerialDate2, GetFormattedNum, IsValidEmail, IsValidS, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import FooterBar from '../Footer/Footer';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";

//빙고 주문하기 2단계
class BingoOrder2 extends React.Component
{
  state = {
    loading: false,

    ageAll: true,
    age10: false,
    age20: false,
    age30: false,
    age40: false,
    age50: false,

    gender: 0, //전체(0), 남성(1), 여성(2)

    adsName: APP_MODE === 0 ? '테스트' : '', //광고명

    dateSelected: false,
    selectedDate: APP_MODE === 0 ? (new Date()) : null,
    requestDate: APP_MODE === 0 ? 202409200000 : null, //광고시작희망일시, Date 객체
    contactName: APP_MODE === 0 ? '담당자' : '',
    contactPhone: APP_MODE === 0 ? '0100000000' : '',
    contactEmail: APP_MODE === 0 ? 'choi98772@naver.com' : '',

    payMode: APP_MODE === 2 ? 2 : 0, //카드(0), 가상계좌(1), 계좌이체(2)
    wName: APP_MODE === 0 ? '입금자' : '', //입금자명

    termCheck: false,
  };


  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  //전체 나이대 선택 또는 취소
  setAgeAll = (v) =>
  {
    this.setState((prevState) => ({...prevState, ageAll: v, age10: false, age20: false, age30: false, age40: false, age50: false}));
  }

  //나이대선택
  setAgeInfo = (age, v) =>
  {
    switch(age)
    {
      case 10:
        this.setState((prevState) => ({...prevState, ageAll: false, age10: v}));
        break;
      case 20:
        this.setState((prevState) => ({...prevState, ageAll: false, age20: v}));
        break;
      case 30:
        this.setState((prevState) => ({...prevState, ageAll: false, age30: v}));
        break;
      case 40:
        this.setState((prevState) => ({...prevState, ageAll: false, age40: v}));
        break;
      case 50:
        this.setState((prevState) => ({...prevState, ageAll: false, age50: v}));
        break;
      default:
        return;
    }
  }

  //성별 타입
  setGenderType = (v) =>
  {
    this.setState((prevState) => ({...prevState, gender : v}));
  }
  
  //광고명
  setAdsName = (v) =>
  {
    this.setState((prevState) => ({...prevState, adsName : v}));
  }

  //시작희망일시
  setRequestDate = (v) =>
  {
    this.setState((prevState) => ({...prevState, requestDate : v}));
  }

  //담당자명
  setContactName = (v) =>
  {
    this.setState((prevState) => ({...prevState, contactName : v}));
  }

  //담장자전화
  setContactPhone = (v) =>
  {
    this.setState((prevState) => ({...prevState, contactPhone : v}));
  }

  //담당자이메일
  setContactEmail = (v) =>
  {
    this.setState((prevState) => ({...prevState, contactEmail : v}));
  }

  //결제방식
  setPayMode = (v) =>
  {
    this.setState((prevState) => ({...prevState, payMode : v}));
  }

  //입금자명
  setWname = (v) =>
  {
    this.setState((prevState) => ({...prevState, wName : v}));
  }

  //약관동의
  setTermCheck = (v) =>
  {
    this.setState((prevState) => ({...prevState, termCheck : v}));
  }

  setSelectedDate = (v) =>
  {
    this.setState((prevState) => ({...prevState, dateSelected: true, selectedDate : v}));
  }
  //사이징----------------------------------------------------------------------------------------------
  
  componentDidMount() {
    /*const date = new Date();
    date.setDate(date.getDate() + 5);

    this.setSelectedDate(date);
    this.setRequestDate(GetDateToSerialDate2(date));*/
  }

  componentWillUnmount = () =>
  {
  }

  //결제하기 버튼이 눌림
  handlePurchase = () =>
  {
    if (this.state.loading)
      return;

    const checkValid = true;
    let ageMask = 0;

    if (checkValid)
    {
      if (!IsValidS(this.state.adsName))
      {
        alert('광고명을 입력해 주세요.');
        return;
      }
    
      if (this.state.ageAll)
        ageMask |= 0x1 | 0x2 | 0x4 | 0x8 | 0x10;
      else
      {
        if (this.state.age10)
          ageMask |= 0x1;
        if (this.state.age20)
          ageMask |= 0x2;
        if (this.state.age30)
          ageMask |= 0x4;
        if (this.state.age40)
          ageMask |= 0x8;
        if (this.state.age50)
          ageMask |= 0x10;
      }
  
      if (ageMask === 0)
      {
        alert(`노출 연령대를 선택해 주세요.`);
        return;
      }
  
      switch(this.state.gender)
      {
        case 0:
        case 1:
        case 2:
          break;
        default:
          alert('노출 성별을 선택해 주세요');
          return;
      }
  
      /*if (this.state.requestDate === 0)
      {
        alert(`광고 시작 희망 일시를 선택해 주세요.`);
        return;
      }*/
  
      if (!IsValidS(this.state.contactName))
      {
        alert(`담당자명을 입력해 주세요.`);
        return;
      }
  
      if (!IsValidS(this.state.contactPhone))
      {
        alert(`담당자 연락처를 입력해 주세요.`);
        return;
      }
  
      if (!IsValidS(this.state.contactEmail))
      {
        alert(`연락 가능한 이메일 주소를 입력해 주세요.`);
        return;
      }

      if (!IsValidEmail(this.state.contactEmail))
      {
        alert(`이메일 주소를 올바르게 입력해 주세요.`);
        return;
      }
  
      switch(this.state.payMode)
      {
        case 0:
        case 1:
        case 2:
          break;
        default:
          alert('결제 수단을 선택해 주세요.');
          return;
      }
  
      if (this.state.payMode === 2)
      {
        if (!IsValidS(this.state.wName))
        {
          alert(`입금자명을 입력해 주세요.`);
          return;
        }
      }
  
      if (!this.state.termCheck)
      {
        alert(`주문 유의 사항에 동의해 주세요.`);
        return;
      }
    }
    
    this.props.handleNextStep(1, {
      adsName: this.state.adsName,
      ageMask: ageMask,
      gender: this.state.gender,
      requestDate: !this.state.requestDate ? 0 : this.state.requestDate,
      contactName: this.state.contactName,
      contactPhone: this.state.contactPhone,
      contactEmail: this.state.contactEmail,
      payMode: this.state.payMode,
      wName: this.state.wName,
    });
  }

  render()
  {
    return (
      <div className='bingocont colel' style={{width: this.props.contWidth, height: this.props.height}}>
        <div className='bingocont2 coltl' style={{width: this.props.contWidth - 40 - 50, minWidth: this.props.contWidth - 40 - 50, height: this.props.height - 100}}>
          <label className='font20 wt600 color31 mgtop20'>빙고팡팡 광고</label>

          <div className='wd100p rowlb mgtop20 mgbt50'>
            <div className='bingoodleft'>
              <div className='bingoodbox1 colcc'>
                <div className='bingoodin coltl'>
                  <label className='font20 wt500 color31'>광고를 생성합니다</label>

                  <label className='font15 wt500 color31 mgtop10'>광고명</label>

                  <input type='text' value={this.state.adsName} onChange={e => this.setAdsName(e.target.value)} className='bingoodinp font16 wt400 color31 mgtop5' placeholder='광고별로 쉽게 구별할 수 있도록 작성해 주세요' maxLength={30}/>

                  <label className='font15 wt500 color31 mgtop20'>광고 타겟</label>

                  <label className='font15 wt400 color31 mgtop10'>연령<span className='colorb3'>(중복 선택 가능)</span></label>

                  <div className={this.state.ageAll ? 'bingoselbox1 rowlc btncur mgtop5' : 'bingoselbox2 rowlc btncur mgtop5'} onClick={() => this.setAgeAll(!this.state.ageAll)}>
                    <img src={GetImgUrl(this.state.ageAll ? 'bingo/sel.png' : 'bingo/sel2.png')} alt="선택" className='mglt5'/>
                    <label className={this.state.ageAll ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>전체</label>
                  </div>

                  <div className='wd100p rowsc mgtop10'>
                    <div className={this.state.age10 ? 'bingoselbox1 rowlc btncur' : 'bingoselbox2 rowlc btncur'} onClick={() => this.setAgeInfo(10, !this.state.age10)}>
                      <img src={GetImgUrl(this.state.age10 ? 'bingo/sel.png' : 'bingo/sel2.png')} alt="선택" className='mglt5'/>
                      <label className={this.state.age10 ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>10대</label>
                    </div>

                    <div className={this.state.age20 ? 'bingoselbox1 rowlc btncur' : 'bingoselbox2 rowlc btncur'} onClick={() => this.setAgeInfo(20, !this.state.age20)}>
                      <img src={GetImgUrl(this.state.age20 ? 'bingo/sel.png' : 'bingo/sel2.png')} alt="선택" className='mglt5'/>
                      <label className={this.state.age20 ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>20대</label>
                    </div>

                    <div className={this.state.age30 ? 'bingoselbox1 rowlc btncur' : 'bingoselbox2 rowlc btncur'} onClick={() => this.setAgeInfo(30, !this.state.age30)}>
                      <img src={GetImgUrl(this.state.age30 ? 'bingo/sel.png' : 'bingo/sel2.png')} alt="선택" className='mglt5'/>
                      <label className={this.state.age30 ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>30대</label>
                    </div>

                    <div className={this.state.age40 ? 'bingoselbox1 rowlc btncur' : 'bingoselbox2 rowlc btncur'} onClick={() => this.setAgeInfo(40, !this.state.age40)}>
                      <img src={GetImgUrl(this.state.age40 ? 'bingo/sel.png' : 'bingo/sel2.png')} alt="선택" className='mglt5'/>
                      <label className={this.state.age40 ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>40대</label>
                    </div>

                    <div className={this.state.age50 ? 'bingoselbox1 rowlc btncur' : 'bingoselbox2 rowlc btncur'} onClick={() => this.setAgeInfo(50, !this.state.age50)}>
                      <img src={GetImgUrl(this.state.age50 ? 'bingo/sel.png' : 'bingo/sel2.png')} alt="선택" className='mglt5'/>
                      <label className={this.state.age50 ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>50대</label>
                    </div>
                  </div>

                  <label className='font15 wt400 color31 mgtop15'>성별</label>

                  <div className='wd100p rowlc mgtop5'>
                    <div className={this.state.gender === 0 ? 'bingoselbox1 rowlc btncur mgrt5' : 'bingoselbox2 rowlc btncur mgrt5'} onClick={() => this.setGenderType(0)}>
                      <img src={GetImgUrl(this.state.gender === 0 ? 'bingo/sel.png' : 'bingo/sel2.png')} alt="선택" className='mglt5'/>
                      <label className={this.state.gender === 0 ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>전체</label>
                    </div>

                    <div className={this.state.gender === 2 ? 'bingoselbox1 rowlc btncur mgrt5' : 'bingoselbox2 rowlc btncur mgrt5'} onClick={() => this.setGenderType(2)}>
                      <img src={GetImgUrl(this.state.gender === 2 ? 'bingo/sel.png' : 'bingo/sel2.png')} alt="선택" className='mglt5'/>
                      <label className={this.state.gender === 2 ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>여성</label>
                    </div>

                    <div className={this.state.gender === 1 ? 'bingoselbox1 rowlc btncur' : 'bingoselbox2 rowlc btncur'} onClick={() => this.setGenderType(1)}>
                      <img src={GetImgUrl(this.state.gender === 1 ? 'bingo/sel.png' : 'bingo/sel2.png')} alt="선택" className='mglt5'/>
                      <label className={this.state.gender === 1 ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>남성</label>
                    </div>
                  </div>

                  <label className='font15 wt400 color31 mgtop15'>지역</label>

                  <div className='bingomapbox rowcc mgtop5'>
                    <label className='font16 wt400 color31'>지도에서 위치 선택하기</label>
                  </div>

                  <label className='font15 wt400 color20'>※ 준비 중인 서비스입니다.</label>
                </div>
              </div>

              <div className='bingoodbox1 colcc mgtop10'>
                <div className='bingoodin coltl'>
                  <label className='font16 wt500 color31'>광고 정보</label>

                  <label className='font15 wt400 color31 mgtop10'>광고 시작 희망 일시<span className='colorb3'>(선택)</span></label>

                  <div className='bingodatebox1 rowlc mgtop5'>
                    <DatePicker selected={this.state.selectedDate} onChange={(date) => {
                        this.setSelectedDate(date); console.log(`date selected ${date}`);
                        this.setRequestDate(GetDateToSerialDate2(date));
                      }}
                      showTimeSelect
                      fixedHeight
                      placeholderText='주문 완료 후 집행까지 3~5영업일 소요됩니다'
                       locale={ko}
                       //timeFormat="p"
                       //timeIntervals={30}
                       //dateFormat="Pp"
                       dateFormat="yyyy-MM-dd h:mm aa"

                      customInput={<input type='text' className='bingodateinp mglt10 font16 fflv1 color1e' readOnly={true}
                      />}
                    />
                  </div>
                  <label className='font15 wt400 color31 mgtop25'>담당자 정보</label>
                  <input type='text' value={this.state.contactName} onChange={e => this.setContactName(e.target.value)} className='bingoodinp font16 wt400 color31 mgtop5' placeholder='담당자명을 입력해 주세요' maxLength={20}/>

                  <input type='text' value={this.state.contactPhone} onChange={e => this.setContactPhone(e.target.value)} className='bingoodinp font16 wt400 color31 mgtop10' placeholder='연락처를 입력해 주세요' maxLength={20}/>

                  <label className='font15 wt400 color31 mgtop25'>이메일</label>
                  <input type='text' value={this.state.contactEmail} onChange={e => this.setContactEmail(e.target.value)} className='bingoodinp font16 wt400 color31 mgtop5' placeholder='사용중인 이메일 주소를 입력해 주세요' maxLength={48}/>

                  <div className='bingodescbox2 rowlc mgtop10'>
                    <label className='font16 wt400 color31 mglt10'>광고에 필요한 정보가 있는 경우 이메일로 보내드려요<br/>ex) 최저가몰 주문자 정보, 경품 당첨자 정보</label>
                  </div>

                  <label className='font15 wt400 color31 mgtop25'>광고 비용</label>

                  <div className='bingodatebox1 rowlc mgtop5'>
                    <label className='font16 wt400 color31 mglt10'>{GetFormattedNum(getBingoDefaultPrice(this.props.gameType))}원 <span className='colorb3'>(VAT별도)</span></label>
                  </div>

                  <div className='bingodescbox2 colcl mgtop10'>
                    <div className='rowcc'>
                      <label className='bgodtext font16 wt500 color31 mglt10'>예상 플레이수</label>
                      <label className='font16 wt500 color31 mglt10'>{GetFormattedNum(getBingoTotalGameCount(this.props.gameType))}회</label>
                    </div>
                    {
                      checkVideoBingo(this.props.gameType) ? (
                        <div className='rowcc'>
                          <label className='bgodtext font16 wt500 color31 mglt10'>예상 동영상 시청수</label>
                          <label className='font16 wt500 color31 mglt10'>{GetFormattedNum(getBingoTotalVideoCount(this.props.gameType))}회</label>
                        </div>
                      ) : null
                    }
                  </div>

                  <label className='font15 wt400 color31 mgtop25'>결제방식</label>
                  
                  <div className='wd100p rowsc mgtop10'>
                    <button className={this.state.payMode === 0 ? 'bingopay1 font16 wt500 color20 btncur' : 'bingopay2 font16 wt500 color31 btncur'} onClick={() => {
                      if (APP_MODE === 2)
                      {
                        alert(`준비중입니다.`);
                        return;
                      }
                      this.setPayMode(0);
                      }}>카드</button>
                    <button className={this.state.payMode === 1 ? 'bingopay1 font16 wt500 color20 btncur' : 'bingopay2 font16 wt500 color31 btncur'} onClick={() => {
                      if (APP_MODE === 2)
                      {
                        alert(`준비중입니다.`);
                        return;
                      }
                      this.setPayMode(1);
                      }}>가상계좌</button>
                    <button className={this.state.payMode === 2 ? 'bingopay1 font16 wt500 color20 btncur' : 'bingopay2 font16 wt500 color31 btncur'} onClick={() => this.setPayMode(2)}>계좌이체</button>
                  </div>

                  {
                    this.state.payMode === 2 ? (
                      <input type='text' value={this.state.wName} onChange={e => this.setWname(e.target.value)} className='bingoodinp font16 wt400 color31 mgtop10' placeholder='입금자명을 입력해 주세요' maxLength={20}/>
                    ) : null
                  }
                  

                  <div className='wd100p rowsc mgtop50'>
                    <button className='bingobtn6 font16 wt600 colorw btncur' onClick={() => this.props.handleNextStep(2, null)}>이전</button>
                    <button className='bingobtn7 font16 wt600 colorw btncur' onClick={this.handlePurchase}>결제하기</button>
                  </div>
                </div>
              </div>
            </div>

            <div className='bingodescbox3 coltl mglt30 btncur' onClick={() => this.setTermCheck(!this.state.termCheck)}>
              <div className='rowlc'>
                <img src={GetImgUrl(this.state.termCheck ? "bingo/sel.png" : "bingo/sel2.png")} alt="선택" className='mglt15 mgrt5'/>
                <label className='font16 wt500 color31 btncur'>유의사항을 확인한 후 동의하시면 체크한 후 결제를 진행해 주세요</label>
              </div>
              
              <label className='font15 wt400 color31 mglt40 btncur'>· 결제가 완료되면 수정이 불가능하니 신중히 입력해주세요.<br/>· 광고가 담당자 정보를 기반으로 진행되므로, 정확히 입력해 주세요.</label>
            </div>
          </div>
          
        </div>
        <FooterBar handleMenu={this.handleMenu}/>
      </div>
    );
  }
}

export default BingoOrder2;