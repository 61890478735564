import './Bingo.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';

const BingoDescGBanner = (props) =>
{
  return (
    <div className='wd100p coltl mgtop10'>
      <label className='font15 wt400 color31 lht23'>빙고팡팡 게임 내 노출되어 있으며, 게임이 진행되는 동안 광고 배너 이미지가 노출되어 광고주의 브랜드를 반복적으로 노출할 수 있습니다.<br/>머니팡애즈에 광고 배너 이미지를 제작해드리기에 필요한 이미지와 텍스트를 첨부해주시면 제작을 도와드리겠습니다.<br/><br/><span className='wt600 color20'>빙고팡팡 게임 배너 제작 가이드</span><br/><span className='wt600'>이미지형 배너</span></label>
      
      <label className='font15 wt400 color31 lht23'>
        ① 여백 : 휴대폰 내, 외 좌우 여백을 의미합니다.<br/>&nbsp;&nbsp;&nbsp;&nbsp;어떤 요소를 넣든 잘려나가는 공간이기 때문에 공간을 비웁니다.<br/>
        ② 메인 문구 : 띄어쓰기 포함 14자<br/>
        ③ 서브 문구 : 띄어쓰기 포함 19자<br/>
        ④ 이미지 : 300*150px 크기의 JPEG 또는 PNG<br/>
      </label>

      <label className='font15 wt400 color31 lht23'><br/>
        <span className='wt600'>텍스트형 배너</span><br/>
        ② 메인 문구 : 띄어쓰기 포함 21자<br/>
        ③ 서브 문구 : 띄어쓰기 포함 28자<br/>
      </label>

      <ul className='wd90p font15 wt400 color31 lht23'>
        <li>이미지형 배너 또는 텍스트형 배너 중 선택해 주세요.</li>
        <li>광고 배너 이미지는 하나의 디자인 양식으로 고정되어 제작되므로, 디자인 변경은 불가능합니다.</li>
      </ul>

      <div className='wd100p rowcc mgtop10'>
        <img src={GetImgUrl("bingo/stp24.png")} alt="설명" className='bgst2img5'/>
      </div>
    </div>
  );
}

export default BingoDescGBanner;