import './Bingo.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';
import { GetFormattedNum } from '../../Util/Util';
import BingoCardAni from './BingoCardAni';

const BingoCard = (props) =>
{
  const selected = props.selected === props.index;

  return (
    <div className={selected ? 'bingocard1 colsc btncur' : 'bingocard2 colsc btncur'} onClick={() => props.handleSelectGame(props.index)}>
      <div className='bingocardin coltl mgtop20'>

        <div className='wd100p rowsc btncur'>
          <label className='font20 wt600 color31 btncur'>{props.title}</label>
          <img src={GetImgUrl(selected ? 'bingo/sel.png' : 'bingo/sel2.png')} alt="선택" />
        </div>

        <BingoCardAni gameCode={props.index} selected={selected}/>

        <div className='rowcr mgtop10'>
          <label className='font20 wt600 color31'>{GetFormattedNum(props.price)}원</label>
          <label className='font15 wt400 colorb3 mglt5'>(VAT 별도)</label>
        </div>

        <div className='rowcc mgtop20'>
          <img src={GetImgUrl('bingo/chk.png')} alt="체크" className='mgrt5'/>
          <label className='font16 wt500 color31'><span className='wt600 color20'>CPM {GetFormattedNum(props.cpm)}원</span> 이하 보장</label>
        </div>

        <div className='rowcc mgtop10'>
          <img src={GetImgUrl('bingo/chk.png')} alt="체크" className='mgrt5'/>
          <label className='font16 wt500 color31'>빙고 플레이 <span className='wt600 color20'>{GetFormattedNum(props.tc)}회</span></label>
        </div>

        <div className='rowcc mgtop10'>
          <img src={GetImgUrl('bingo/chk.png')} alt="체크" className='mgrt5'/>
          <label className='font16 wt500 color31'>유저 <span className='wt600 color20'>{GetFormattedNum(props.uc)}명 이상 광고 시청</span></label>
        </div>
        

        <div className='rowcl mgtop10'>
          <img src={GetImgUrl('bingo/chk.png')} alt="체크" className='mgrt5 mgtop5'/>
          <label className='font16 wt500 color31'>링크 클릭 시 <span className='wt600 color20'>추가 리워드 제공</span><br/><span className='font14 colorb3'>(링크 : 광고주가 선택)</span></label>
        </div>
      </div>

      <div className='bingocardin coltl'>
        <button className='bingobtn5 font16 wt500 colorw btncur mgbt20' onClick={() => props.handleStartGame(props.index)}>광고 생성하기</button>
      </div>
    </div>
  );
}

export default BingoCard;