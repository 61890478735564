import './Bingo.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';

const BingoDescSb = (props) =>
{
  return (
    <div className='wd100p coltl mgtop10'>
      <label className='font15 wt400 color31 lht23'>빙고팡팡 내에서 빙고가 플레이되려면 심볼이 필수이며, 심볼이 있어야 빙고가 생성됩니다.<br/><br/><span className='color20 wt600'>일반형 심볼 제작 가이드</span></label>
      
      <ul className='wd90p font15 wt400 color31 lht23'>
        <li>일반형 심볼 사이즈 : 900*600px</li>
        <li>광고 영역과 게임 영역이 분리되어 있으며, 광고 이미지를 심볼로 제작하여 빙고를 생성하므로, 광고 영역에 들어갈 이미지가 필요합니다</li>
        <li>광고 이미지는 300*600px로 3등분이 되기 때문에 텍스트가 잘릴 수 있다는 점에 유의해 주세요.</li>
      </ul>

      <div className='wd100p rowcc'>
        <img src={GetImgUrl("bingo/stp231.png")} alt="설명" className='bgst2img3'/>
      </div>

      <label className='font15 wt400 color31 lht23 mgtop30'><span className='color20 wt600'>집중형 심볼 제작 가이드</span></label>
      
      <ul className='wd90p font15 wt400 color31 lht23'>
        <li>집중형 심볼 사이즈 : 300*300px</li>
        <li>주요 심볼 3개, 일반 심볼 5개, 조커 심볼1개로 총 9개의 심볼이 구성되어 있습니다.</li>
        <li>주요심볼, 일반심볼은 이미지·이미지+텍스트·텍스트 중 자유롭게 디자인할 수 있습니다.<br/>단, 조커심볼의 경우 로고 파일로만 제작됩니다.</li>
      </ul>

      <div className='wd100p rowcc'>
        <img src={GetImgUrl("bingo/stp232.png")} alt="설명" className='bgst2img4'/>
      </div>
    </div>
  );
}

export default BingoDescSb;